import type { User } from "~/models/User"
import { inferRouteParams } from "~/common/helperRoutes"
import { listOfNotProtectedRoutes } from "~/common/routes"

export default defineNuxtRouteMiddleware(async (to, from) => {
    const can = useCan()

    if (listOfNotProtectedRoutes.includes(to.path)) {
        return
    }
    const user = useSanctumUser<User>()
    const permissions = inferRouteParams(to.path, to.params)
    let autorize = false
    if (permissions.length > 0) {
        autorize = permissions.every((permission) =>
            can(permission.permissions, permission.environment)
        )
        if (!autorize) {
            return navigateTo("/404")
        }
    }

    return
})
