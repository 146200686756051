<template>
    <q-layout view="hHh lpR fff">
        <q-header elevated>
            <HomeNav></HomeNav>
        </q-header>
        <slot name="drawer">
            <LeftDrawer
                v-model="leftDrawerOpen"
                show-if-above
                class="tw-shadow-md"
            >
                <LeftMenu :menu="main_menu_filtered" class="tw-pl-6" />
            </LeftDrawer>
        </slot>
        <q-page-container>
            <q-page>
                <slot name="offers"></slot>
                <div class="tw-p-5">
                    <slot></slot>
                </div>
                <slot name="most_sold"></slot>
            </q-page>
        </q-page-container>

        <q-footer class="footer">
            <HomeFooter></HomeFooter>
        </q-footer>
    </q-layout>
</template>

<script setup lang="ts">
import HomeNav from "~/components/HomeNav.vue"
import HomeFooter from "~/components/HomeFooter.vue"
import LeftDrawer from "~/components/LeftDrawer.vue"
import { onMounted, ref } from "vue"
import { useLeftMenu } from "~/composables/LefMenu"
import { useNotification } from "~/composables/Notification"
import { watch } from "vue"
import { computed } from "vue"
import { QAjaxBar, useQuasar } from "quasar"
import BreadCrumbs from "~/components/BreadCrumbs.vue"
import { breadCrumb_menu } from "~/common/crumbs_menu"
import { timeClearSpinner } from "~/common/helpers"
import { useMenuItemSelectedStore } from "~/stores/MenuItemSelected"

/**
 *
 *  Props and emits section
 *
 *
 */

/**
 *
 *  Ref, computed and hooks section
 *
 *
 */

const router = useRouter()
const leftDrawerOpen = ref(false)
const main_menu_filtered = useLeftMenu()
const menuItemSelectedStore = useMenuItemSelectedStore()
const shopCartStore = useShopCartStore()
const $q = useQuasar()
let timer: NodeJS.Timeout
const { intentNotificate } = useNotification()
const bar = ref<QAjaxBar | null>(null)
const initSettingsStore = useDefaultSettingsStore()

shopCartStore.fetchShopCart()
const { data } = await useOFetch("GET", "init-settings")
initSettingsStore.setSettings(data?.data)

/**
 *
 *  Life cicle events
 *
 *
 */

onMounted(async () => {
    //intentNotificate(value)

    // router.on('start', () => {

    //   $q.loading.show({
    //     delay:150,
    //     spinnerColor:'primary'
    //   })
    //   timer=setTimeout(()=>{
    //     $q.loading.hide()
    //     clearTimeout(timer)
    //   },timeClearSpinner)

    // })

    // router.on('finish', () => {

    //   $q.loading.hide()
    //   clearTimeout(timer)

    // })

    const route_name = router.currentRoute.value.name

    if (route_name) {
        menuItemSelectedStore.setByRoute(route_name.toString())
    }
})

// watch(() => props.flash.notification, value => {

//   intentNotificate(value)

// })

/**
 *
 *  Functions section
 *
 *
 */
</script>

<style>
.shadown {
    box-shadow: 0px 4px 4px 0px rgba(6, 116, 191, 0.15);
}
.body-blur {
    filter: blur(3px);
}
</style>
