import { PERMISSIONS } from "~/common/permissions"
import type { PermissionRoute } from "~/types/permission"

export const listOfNotProtectedRoutes = [
    "/",
    "/store",
    "/store/[store]",
    "/offers",
    "/auth/login",
    "/auth/register",
    "/auth/resetpassword",
    "/auth/forgotpassword",
    "/auth/verifyemail",
    "/auth/confirmPassword",
]

export const listOfProtectedRoutes: Record<string, PermissionRoute[]> = {
    "/admin/users": [
        { permissions: [PERMISSIONS.READ_USERS], environment: "marketplace" },
    ],
    "/admin/users/create": [
        { permissions: [PERMISSIONS.CREATE_USERS], environment: "marketplace" },
    ],
    "/admin/roles": [
        { permissions: [PERMISSIONS.READ_ROLES], environment: "marketplace" },
    ],
    "/admin/roles/create": [
        { permissions: [PERMISSIONS.CREATE_ROLES], environment: "marketplace" },
    ],
    "/products/create": [
        {
            permissions: [PERMISSIONS.CREATE_PRODUCTS],
        },
    ],
    "/categories/create": [
        {
            permissions: [PERMISSIONS.CREATE_CATEGORIES],
        },
    ],
    "/categories": [
        {
            permissions: [PERMISSIONS.READ_CATEGORIES],
        },
    ],
    "/admin/offers": [
        { permissions: [PERMISSIONS.READ_OFFERS], environment: "store" },
    ],
    "/admin/offers/create": [
        { permissions: [PERMISSIONS.CREATE_OFFERS], environment: "store" },
    ],
}

export const listOfProtectedWithParamsRoutes: Record<
    string,
    PermissionRoute[]
> = {
    "/admin/users/[id]": [
        { permissions: [PERMISSIONS.UPDATE_USERS], environment: "marketplace" },
    ],
    "/admin/roles/[id]": [
        { permissions: [PERMISSIONS.UPDATE_ROLES], environment: "marketplace" },
    ],
    "/admin/offers/[id]": [
        { permissions: [PERMISSIONS.UPDATE_OFFERS], environment: "store" },
    ],
    "/products/[slug]/edit": [{ permissions: [PERMISSIONS.UPDATE_PRODUCTS] }],
    "/categories/[id]": [{ permissions: [PERMISSIONS.UPDATE_CATEGORIES] }],
}
