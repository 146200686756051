import type { MenuItem } from "~/models/MenuItem"
import { main_menu, navbar_menu } from "~/common/main_menu"
import { cloneDeep } from "lodash"

export function useFilteredMenu() {
    const canPermission = useCan()

    const menu = cloneDeep(main_menu)

    function canShow(item: MenuItem): boolean {
        if (hasChildren(item)) {
            item.children = item.children?.filter((i) => canShow(i))

            if (item.children?.length == 0) {
                return false
            }
        }

        //return can(item?.can ?? null)
        return item?.can ? canPermission(item?.can, item?.environment) : true
    }

    function hasChildren(item: MenuItem): boolean {
        if (item.children && item.children.length > 0) {
            return true
        } else {
            return false
        }
    }

    return menu.filter((i) => canShow(i))
}

export function useMenu(menu: MenuItem[]) {
    function hasChildren(item: MenuItem): boolean {
        if (item.children && item.children?.length > 0) {
            return true
        } else {
            return false
        }
    }

    function canShow(item: MenuItem): boolean {
        const can = useCan()
        if (hasChildren(item)) {
            for (const child of item.children!) {
                if (canShow(child)) {
                    return true
                }
            }

            return false
        }

        return item?.can ? can(item?.can, item?.environment) : true
    }

    return menu.filter((i) => {
        return canShow(i) == true
    })
}

export function useLeftMenu() {
    return useMenu(main_menu)
}

export function useNavbarMenu() {
    return navbar_menu.filter((i) => {
        //return i.show_condition != null ? i.show_condition!(props) : true
        return true
    })
}
