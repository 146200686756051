import type { User } from "~/models/User"

export function useCan() {
    const { user } = useSanctumAuth<User>()
    function can(
        permission: string[] | string,
        environment?: "store" | "marketplace"
    ): boolean {
        if (permission && user.value) {
            if (typeof permission == "string") {
                if (!environment) {
                    return (
                        user.value.all_permissions.findIndex(
                            (p) => p == permission
                        ) != -1 ||
                        user.value.stores_manager_of
                            .flatMap((s) => s.permissions)
                            .findIndex((p) => p == permission) != -1
                    )
                }

                if (environment == "marketplace") {
                    return (
                        user.value.all_permissions.findIndex(
                            (p) => p == permission
                        ) != -1
                    )
                }

                if (environment == "store") {
                    return (
                        user.value.stores_manager_of
                            .flatMap((s) => s.permissions)
                            .findIndex((p) => p == permission) != -1
                    )
                }
            }

            for (const permissionElement of permission) {
                if (!environment) {
                    if (
                        user.value.all_permissions.findIndex(
                            (p) => p == permissionElement
                        ) != -1 ||
                        user.value.stores_manager_of
                            .flatMap((s) => s.permissions)
                            .findIndex((p) => p == permissionElement) != -1
                    ) {
                        return true
                    }
                }

                if (environment == "marketplace") {
                    if (
                        user.value.all_permissions.findIndex(
                            (p) => p == permissionElement
                        ) != -1
                    ) {
                        return true
                    }
                }

                if (environment == "store") {
                    if (
                        user.value.stores_manager_of
                            .flatMap((s) => s.permissions)
                            .findIndex((p) => p == permissionElement) != -1
                    ) {
                        return true
                    }
                }
            }

            return false
        }

        return false
    }

    return can
}

export function useCanInStore() {
    const { user } = useSanctumAuth<User>()

    function canInStore(permission: string[] | string, store_id: number) {
        if (permission && user.value) {
            if (typeof permission == "string") {
                return (
                    user.value.stores_manager_of
                        .find((s) => s.id == store_id)
                        ?.permissions.findIndex((p) => p == permission) != -1
                )
            }

            return permission.every((p) =>
                user.value?.stores_manager_of
                    .find((s) => s.id == store_id)
                    ?.permissions.includes(p)
            )
        }
    }

    return canInStore
}
