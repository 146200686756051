<template>
    <q-select
        v-model="proxyValue"
        color="primary"
        :label="label"
        :error-message="formattedErrorMessages"
        :error="errors != null"
        :options="options"
        :option-label="optionLabel"
        :option-value="optionValue"
        :map-options="optionValue != null && optionLabel != null"
        :emit-value="optionValue != null && optionLabel != null"
        class="tw-min-w-[100px]"
        :dense="dense"
        :multiple="multiple"
    >
        <template #selected v-if="$slots.selected">
            <slot
                name="selected"
                :value="proxyValue"
                :label="getOptionLabel(proxyValue, options)"
            />
        </template>
        <template #prepend v-if="$slots.prepend">
            <slot name="prepend" />
        </template>
        <template #append v-if="proxyValue && clearable">
            <q-icon
                name="cancel"
                @click.stop.prevent="proxyValue = null"
                class="cursor-pointer"
                size="xs"
            ></q-icon>
        </template>
        <template #after>
            <slot name="after" />
        </template>
        <template #label>
            <slot name="label">
                <div class="tw-flex tw-flex-row tw-text-left">
                    <label
                        v-if="required"
                        class="tw-text-red-700 tw-font-extrabold tw-mr-1"
                        >*</label
                    >
                    {{ label }}
                </div>
            </slot>
        </template>
        <template #before v-if="$slots.before">
            <slot name="before" />
        </template>
        <slot name="tooltip">
            <q-tooltip v-if="required && label">
                {{
                    $t("messages.this_field") +
                    label?.toLowerCase() +
                    $t("messages.field_required")
                }}
            </q-tooltip>
            <q-tooltip v-else-if="required">
                {{ $t("messages.this_field") + $t("messages.field_required") }}
            </q-tooltip>
        </slot>
    </q-select>
</template>

<script setup lang="ts">
import { computed } from "vue"

const props = defineProps<{
    modelValue?: any
    label?: string
    options?: any[]
    optionLabel?: string
    optionValue?: string
    errors?: string | string[]
    required?: boolean
    clearable?: boolean
    dense?: boolean
    multiple?: boolean
}>()

const emits = defineEmits(["update:modelValue", "update:errors"])

const proxyValue = computed({
    get() {
        return props.modelValue
    },
    set(val) {
        emits("update:modelValue", val)
        emits("update:errors", null)
    },
})

const errorMessagesArray = computed(() => {
    if (typeof props.errors === "string") {
        return [props.errors] // Convierte a array si es un string
    }
    return props.errors // Ya es un array
})

const formattedErrorMessages = computed(() => {
    //Es un array
    return errorMessagesArray.value?.join(",")
})

function getOptionLabel(value: any, options: any) {
    if (!value) {
        return null
    }

    let v = null

    if (props.optionValue) {
        v = options.find((i: any) =>
            props.optionValue ? i[props.optionValue!] == value : ""
        )
    } else {
        v = options[value]
    }

    if (props.optionLabel) {
        return v[props.optionLabel]
    }

    return v
}
</script>
